/* global confetti */
$().ready(function() {
	if ($("body").hasClass("bingo")) {
		bingo();
	}
});
export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}
export function getNumber() {
	var number = -1;
	while (number < 0 || numbers_seen.includes(number)){
		number = getRandomInt(1, 76);
	}
	numbers_seen.push(number);
	return number;
}
var numbers_seen = [];
export function bingo(){
	$.extend($.fn.disableTextSelect = function() {
		return this.each(function(){
			$(this).css("MozUserSelect","none");
			$(this).css("WebkitUserSelect","none");
			$(this).css("MsUserSelect","none");
			$(this).bind("selectstart",function(){return false;});
			$(this).mousedown(function(){return false;});
		});
	});
	var $container = $(".bingo-card");
	if ($container.find("table").length == 0){
		$(".bingo-card").append("<table><tbody></tbody></table>");
	}
	var $table = $container.find("table");
	var $tbody = $table.find("tbody");
	$tbody.disableTextSelect();
	function new_game(){
		confetti.stop();
		$("body").removeClass("winner");
		$tbody.html("");
		$tbody.addClass("loading");
		numbers_seen = [];
		for (var row = 1; row <=5; row++) {
			var $row = $("<tr></tr>");
			for (var col = 1; col <=5; col++) {
				var $cell = $("<td></td>");
				if (row == 3 && col == 3) {
					$cell.html("Free<br/>Space");
					$cell.addClass("free-space");
				} else {

					$cell.text(getNumber()); //TODO: exclude existing numbers
				}
				$row.append($cell);
			}
			$tbody.append($row);
		}
		var calc_css = $tbody.height();	// eslint-disable-line no-unused-vars
		$tbody.removeClass("loading");
	}
	function get_cell(row,col) {
		return $tbody.find("tr").eq(row - 1).find("td").eq(col - 1);
	}
	function calc_win(){
		var topleft_bottomright_matched = true;
		var topright_bottomleft_matched = true;

		for (var x = 1; x <=5; x++) {
			var col_matched = true;
			var row_matched = true;
			for (var y = 1; y <=5; y++) {
				// Check rows and cols
				//loop through cells in row/col, and if it's not matched the row/col did not win
				if (x!=3 || y!=3) { //Don't check free space
					if (!get_cell(x,y).hasClass("matched")) col_matched= false;
					if (!get_cell(y,x).hasClass("matched")) row_matched= false;
				}
			}
			if (col_matched || row_matched) return true;
			if (x != 3) {
				if (!get_cell(x,x).hasClass("matched")) topleft_bottomright_matched= false;
				if (!get_cell(x,6-x).hasClass("matched")) topright_bottomleft_matched= false;
			}
		}
		//Check diagonals
		if (topleft_bottomright_matched || topright_bottomleft_matched) {
			return true;
		}
		return false;
	}
	function check_win() {
		if (calc_win()) {
			$("body").addClass("winner");
			confetti.start();
		} else {
			$("body").removeClass("winner");
			confetti.stop();
			//clear bingo notification
			//alert('unbingo!');
		}
	}
	new_game();
	$(".new-game").click(new_game);
	$tbody.on("click","td",function(){
		if ($(this).hasClass("free-space")) return;
		if ($(this).hasClass("matched")) {
			$(this).removeClass("matched");
			$(this).find(".match-mark").remove();
			check_win();
		} else {
			$(this).addClass("matched");
			$(this).append("<span class=\"match-mark\"></span>");
			$(this).find(".match-mark").css("background-position", getRandomInt(0,100) + "% " + getRandomInt(0,100) + "% ");
			$(this).find(".match-mark").addClass("daub-"+getRandomInt(1,4));
			check_win();
		}
	});
}